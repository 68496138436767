import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { ExpandMore, LibraryBooks, VideoLibrary } from '@material-ui/icons';
//import LinkedIn from '../assets/linkedIn.svg';
//import Twitter from '../assets/twitter.svg';
//import Facebook from '../assets/faceBookIcon.svg';
import { ReviewModal } from '../components/ContactForms';
import NoContentPlaceholder from '../components/NoContentPlaceholder';
import {
  KeyboardBackspace,
  //Room,
  PlayCircleFilledWhite,
} from '@material-ui/icons';
import {
  CarouselController,
  PostCarousel,
} from '../components/CarouselController';
import * as userActions from '../redux/actions/users';
import * as institutionActions from '../redux/actions/institution';
import * as sessionsActions from '../redux/actions/sessions';
import * as contentActions from '../redux/actions/content';
import * as homeActions from '../redux/actions/home';
import * as commentActions from '../redux/actions/comments';
import * as affiliationsActions from '../redux/actions/affiliations';

import { useDispatch, useSelector } from 'react-redux';
import FollowCard from '../components/FollowCard';
import { useToggle } from '../helpers';
import Truncate from 'react-truncate';
import NavHeader from '../components/NavBarHeader';
import moment from 'moment';
import SendPostModal from '../components/SendPostModal';
import SharePostModal from '../components/SharePostModal';
import CommentPostModal from '../components/CommentPostModal';
import LibraryContentCard from '../components/LibraryContentCard';
import EventCard from '../components/EventCard';
import PostCard from '../components/PostCard';
import CustomAlert from '../components/CustomAlert';
//import ReviewItem from '../components/ReviewItem';

const InstitutionFindoutMore = () => {
  const { institutionID } = useParams();
  const dispatch = useDispatch();
  const users = useSelector((state) => state?.user);
  const userData = users.data;
  const mentors = userData.filter((user) => user.usercategory[0] === 'mentor');
  const institutionLearners = useSelector(
    (state) => state?.institution?.institutionLearners,
  );
  const comments = useSelector((state) => state.comments.data);
  const affiliations = useSelector((state) => state?.affiliations?.data);

  const institutions = useSelector((state) => state.institution);
  const institutionData = institutions?.data;
  const institution = institutions?.institution;
  const { success, error, errorMessage } = useSelector(
    (state) => state.comments,
  );
  const [loading, setLoading] = useState(true);
  const [hideFollow, setHideFollow] = useState([]);

  const instituionOptions = [];
  institutionData?.map((value) => instituionOptions.push(value));

  const selectedInstitution = institutionData.find(
    (value) => value?._id === institutionID,
  );

  const selectedInstitutionEmail = users?.data.filter(
    (user) => user._id === selectedInstitution?.admins[0],
  );

  useEffect(() => {
    dispatch(institutionActions.getInstitutions({}));
    dispatch(institutionActions.getYourMentor());
    dispatch(userActions?.getUsers());
    dispatch(homeActions.getFeeds({ limit: 30 }));
    dispatch(sessionsActions.getAllSessions());
    dispatch(contentActions.getAllContent());
    dispatch(commentActions.getAllComments());
    dispatch(affiliationsActions.getAllAffiliations());
    dispatch(
      institutionActions.getInstitutionLearners(selectedInstitution?._id),
    );
    const userExists = institutionLearners.filter(
      (institutionLearner) =>
        institutionLearner.learner._id === users?.user?._id &&
        institutionLearner.institution === selectedInstitution?._id,
    );

    setHideFollow(userExists);
  }, [selectedInstitution]);

  const handleFollow = () => {
    dispatch(
      institutionActions.createInstitutionLearner({
        id: users?.user?._id,
        institution_id: selectedInstitution?._id,
      }),
    );
    dispatch(
      institutionActions.getInstitutionLearners(selectedInstitution?._id),
    );
  };

  useEffect(() => {
    if (institutionData.length === 0) {
      dispatch(institutionActions.getInstitutions({}));
      setLoading(true);
    } else {
      setLoading(false);
    }

    if (institution?.length === 0) {
      dispatch(institutionActions.getSpecificInstitution(institutionID));
    }
  }, []);

  const mentorComments = comments?.filter(
    (item) => item.idOfCommentedAbout === institutionID,
  );

  const [showReviewModal, setShowReviewModal] = useState(false);

  useEffect(() => {
    if (success.message === 'Comment added successfully') {
      CustomAlert({
        alertType: 'success',
        alertMessage: 'Comment added successfully',
      });
    } else if (error && errorMessage === 'Failed to submit comment') {
      CustomAlert({
        alertType: 'error',
        alertMessage: 'Failed to submit comment',
      });
    }
  }, [success, error]);

  const affiliatedMentors = [];
  affiliations
    ?.filter(
      (item) =>
        item.institution === institutionID && item.affiliateType === 'mentor',
    )
    .map((item) => affiliatedMentors.push(item.affiliate));

  const mentorsList = userData?.filter(
    (value) => value?.usercategory[0] === 'mentor',
  );

  const myMentors = mentorsList?.filter((mentor) =>
    affiliatedMentors.includes(mentor._id),
  );

  return (
    <>
      {institution ? (
        <>
          <NavHeader />
          <div className={`w-full bg-lightgraybg ${loading}`}>
            <div className='mx-auto sm:w-xl md:w-full lg:max-w-screen-xl md:px-4 lg:px-8  '>
              <div className='px-2 py-6 container mx-auto sm:max-w-full md:max-w-full lg:max-w-full  lg:px-0 '>
                <div className='flex flex-col mb-6 lg:justify-between lg:flex-row md:mb-6'>
                  <div className='flex flex-col  container mx-auto'>
                    <div className='flex justify-start w-full   py-4 pb-5 lg:w-full mb-1'>
                      <div
                        onClick={() => window.history.back()}
                        className='text-black font-sans cursor-pointer hover:text-headingtext'
                      >
                        <KeyboardBackspace fontSize='medium' />{' '}
                        <span className='ml-2'>Back</span>
                      </div>
                    </div>

                    <div className='  lg:w-full block mt-1 text-headingtext z-20'>
                      <div className='p-8 flex  flex-col lg:flex-row justify-center lg:justify-between  bg-white rounded filter drop-shadow  md:text-left sm:text-md'>
                        <div className=' mx-auto lg:mx-0 flex flex-col lg:flex-row justify-start'>
                          <div className='mx-auto  h-28 w-28   md:h-36 md:w-36 lg:h-48 lg:w-48  mb-4 lg:mb-0  '>
                            <img
                              src={
                                selectedInstitution?.profilePicture
                                  ?.publicUrl ||
                                `https://ui-avatars.com/api/?name=${selectedInstitution?.institutionName}&bold=true`
                              }
                              alt={selectedInstitution?.institutionName}
                              className='rounded-full object-cover h-auto w-full shadow-md'
                            />
                          </div>
                          <div className='lg:2 px-4 text-center lg:text-left lg:w-9/12 w-full'>
                            <h5 className='mb-3 text-xl lg:text-5xl font-bold leading-none text-charcoal'>
                              {`${selectedInstitution?.institutionName} `}
                            </h5>
                            <p className='mb-5 text-textsecondary opacity-70 text-sm lg:text-xl text-left'>
                              {selectedInstitution?.bio ||
                                'One of the oldest and most prestigious Universities in Africa. A top research university in Africa and beyond.'}
                            </p>
                          </div>
                        </div>

                        <div className=' px-4  sm:px-2 '>
                          <div className='flex flex-col justify-around w-full lg:w-60  h-full '>
                            {hideFollow.length === 0 ? (
                              <button
                                onClick={() => handleFollow()}
                                className='inline-flex font-bold items-center justify-center w-full h-12 mb-4 px-8 py-4 mr-6  border-2 border-darkerblue tracking-wide text-darkerblue text-base sm:text-sm  transition duration-200 rounded-lg shadow-md bg-white hover:bg-darkerblue hover:text-white focus:shadow-outline focus:outline-none'
                              >
                                Follow
                              </button>
                            ) : null}
                            <a
                              href={`mailto: ${selectedInstitutionEmail[0]?.email}`}
                              aria-label='Send Email'
                              title='Send Email'
                              target='_blank'
                              rel='noreferrer'
                            >
                              <button
                                // onClick={() => setShowEmailModal(true)}
                                className='inline-flex  font-bold items-center justify-center w-full h-12 mb-4 px-8 py-4 mr-6  border-2 border-darkerblue tracking-wide text-darkerblue text-base sm:text-sm  transition duration-200 rounded-lg shadow-md bg-white hover:bg-darkerblue hover:text-white focus:shadow-outline focus:outline-none'
                              >
                                Contact us
                              </button>
                            </a>

                            <button
                              onClick={() => setShowReviewModal(true)}
                              className='inline-flex font-bold items-center justify-center w-full h-12 mb-4 px-8 py-4 mr-6  border-2 border-darkerblue tracking-wide text-darkerblue text-base sm:text-sm  transition duration-200 rounded-lg shadow-md bg-white hover:bg-darkerblue hover:text-white focus:shadow-outline focus:outline-none'
                            >
                              Leave a Review
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='lg:w-full block h-full text-headingtext z-10'>
                      <div className=' flex flex-col lg:flex-row justify-between  bg-white  lg:text-left text-md'>
                        <div className=' flex justify-start lg:w-8/12 '>
                          <div className='flex flex-col justify-start pt-6 lg:p-6 w-full'>
                            <TabLayout
                              institution={selectedInstitution}
                              comments={mentorComments?.reverse()}
                              myMentors={myMentors}
                              institutionLearners={institutionLearners}
                            />
                          </div>
                        </div>

                        <div className='lg:w-4/12 justify-around border-l-2 border-lightGrayBg2'>
                          <div className='flexflex-col  justify-start p-6 '>
                            <div className='flex flex-col justify-around mb-9 text-charcoal'>
                              <div className='bg-white w-full'>
                                <div className=''>
                                  <h1 className='p-4  font-body font-semibold text-sm  '>
                                    Follow Mentors / Trainers / Counsellors
                                  </h1>
                                  <div className='w-full border-b border-linecolor mb-5'></div>
                                  <div>
                                    {mentors
                                      .slice(0, 4)
                                      .map((value) =>
                                        value.usercategory[0] === 'mentor' ? (
                                          <FollowCard
                                            key={value._id}
                                            pic={
                                              value.profilePicture?.publicUrl
                                            }
                                            name={`${value.firstname} ${value.lastname}`}
                                            content={
                                              value.usercategory[
                                                value.usercategory.length - 1
                                              ]
                                            }
                                            button='Follow'
                                          />
                                        ) : null,
                                      )}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className='flex flex-col justify-around mb-9 text-charcoal'>
                              <div className='bg-white w-full'>
                                <div className=''>
                                  <h1 className='p-4  font-body font-semibold text-sm  '>
                                    Institution Members
                                  </h1>
                                  <div className='w-full h-full border-b border-linecolor mb-5'></div>
                                  <div>
                                    {institutionLearners.map((value) => (
                                      <FollowCard
                                        key={value._id}
                                        pic={value.profilePicture?.publicUrl}
                                        name={`${value.learner.firstName} ${value.learner.lastName}`}
                                        content={'Learner'}
                                        userId={`${value.learner._id}`}
                                        button='Profile'
                                      />
                                    ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className='bg-white w-full  text-charcoal mb-16'>
                              {/* <div className=''>
                                <h1 className='p-4 font-body font-semibold text-sm'>
                                  Follow Groups / Communities
                                </h1>
                                <div className='w-full border-b border-linecolor mb-5'></div>
                                <div>
                                  <FollowCard
                                    pic={AvatarPlaceholder}
                                    name='Coding girls'
                                    content='Python'
                                    button='Join'
                                  />
                                  <FollowCard
                                    pic={AvatarPlaceholder}
                                    name='Business Startup'
                                    // content='LLM Antoney'
                                    button='Join'
                                  />
                                  <FollowCard
                                    pic={AvatarPlaceholder}
                                    name='Dev Ops'
                                    // content='LLM Antoney'
                                    button='Join'
                                  />
                                </div>
                              </div> */}
                            </div>
                            <div className=' bg-upskillingAlt flex justify-center mb-9 bg-cover bg-center'>
                              {/*<div
                                className=''
                                style={{
                                  background:
                                    'linear-gradient(0deg, rgba(0,0,0,0.83) 0%, rgba(0,0,0,0.2) 38%, rgba(255,255,255,0) 100%)',
                                }}
                              >
                                <div className='flex flex-col justify-around py-20 lg:py-48 px-12 text-white'>
                                  <span className='text-xl lg:text-2xl  font-bold text-center mb-10'>{`Career Guidance after 12th science session`}</span>
                                  <button
                                    type='submit'
                                    className='inline-flex justify-center w-full px-2 lg:px-8 py-2 lg:py-2 border border-white font-semibold text-white text-lg lg:text-xl  transition duration-200 rounded-lg shadow-md bg-none hover:bg-white hover:text-graybg2 focus:shadow-outline focus:outline-none'
                                  >
                                    Join Session
                                  </button>
                                </div>
                              </div>*/}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ReviewModal
            userType={'institution'}
            content={selectedInstitution}
            showReviewModal={showReviewModal}
            setShowReviewModal={setShowReviewModal}
          />
        </>
      ) : null}
    </>
  );
};

const NavigationHeader = ({ activeStatus, setActiveStatus }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  let tabLabel;
  if (activeStatus === 1) tabLabel = 'Home';
  //if (activeStatus === 2) tabLabel = 'About';
  if (activeStatus === 3) tabLabel = 'Feed';
  if (activeStatus === 4) tabLabel = 'Mentors';
  if (activeStatus === 5) tabLabel = 'Library';
  if (activeStatus === 6) tabLabel = 'Events';
  if (activeStatus === 7) tabLabel = 'Members';
  if (activeStatus === 8) tabLabel = 'Courses';
  const [selectedTab, setSelectedTab] = useState(tabLabel);
  return (
    <div className='px-2 flex items-center justify-center lg:justify-start lg:items-start w-full'>
      <ul className='w-full hidden md:flex items-center  border-b border-linecolor'>
        <li
          onClick={() => {
            setActiveStatus(1);
          }}
          className={
            activeStatus === 1
              ? 'py-2 px-2 cursor-pointer  border-b-2 border-main text-darkerblue font-semibold  text-base   leading-none text-center '
              : 'py-2 px-2 cursor-pointer  bg-transparent hover:bg-lightGrayBg font-semibold   text-base   leading-none text-gray5'
          }
        >
          Home
        </li>
        {/*<li
          onClick={() => setActiveStatus(2)}
          className={
            activeStatus === 2
              ? 'py-2 px-2 cursor-pointer  border-b-2 border-main text-darkerblue font-semibold   ml-12  text-base   leading-none text-center'
              : 'py-2 px-2 cursor-pointer ml-12 bg-transparent hover:bg-lightGrayBg font-semibold   text-base   leading-none text-gray5'
          }
        >
          About
        </li>*/}
        <li
          onClick={() => setActiveStatus(3)}
          className={
            activeStatus === 3
              ? 'py-2 px-2 cursor-pointer  border-b-2 border-main text-darkerblue font-semibold   ml-12  text-base   leading-none text-center '
              : 'py-2 px-2 cursor-pointer ml-10 bg-transparent hover:bg-lightGrayBg font-semibold   text-base   leading-none text-gray5'
          }
        >
          Feed
        </li>
        <li
          onClick={() => setActiveStatus(4)}
          className={
            activeStatus === 4
              ? 'py-2 px-2 cursor-pointer  border-b-2 border-main text-darkerblue font-semibold   ml-12  text-base   leading-none text-center'
              : 'py-2 px-2 cursor-pointer ml-10 bg-transparent hover:bg-lightGrayBg font-semibold   text-base   leading-none text-gray5'
          }
        >
          Mentors
        </li>
        <li
          onClick={() => setActiveStatus(7)}
          className={
            activeStatus === 7
              ? 'py-2 px-2 cursor-pointer  border-b-2 border-main text-darkerblue font-semibold   ml-12  text-base   leading-none text-center'
              : 'py-2 px-2 cursor-pointer ml-10 bg-transparent hover:bg-lightGrayBg font-semibold   text-base   leading-none text-gray5'
          }
        >
          Members
        </li>
        <li
          onClick={() => setActiveStatus(8)}
          className={
            activeStatus === 8
              ? 'py-2 px-2 cursor-pointer  border-b-2 border-main text-darkerblue font-semibold   ml-12  text-base   leading-none text-center'
              : 'py-2 px-2 cursor-pointer ml-10 bg-transparent hover:bg-lightGrayBg font-semibold   text-base   leading-none text-gray5'
          }
        >
          Courses
        </li>
        <li
          onClick={() => setActiveStatus(5)}
          className={
            activeStatus === 5
              ? 'py-2 px-2 cursor-pointer  border-b-2 border-main text-darkerblue font-semibold   ml-12  text-base   leading-none text-center'
              : 'py-2 px-2 cursor-pointer ml-10 bg-transparent hover:bg-lightGrayBg font-semibold   text-base   leading-none text-gray5'
          }
        >
          Library
        </li>
        <li
          onClick={() => setActiveStatus(6)}
          className={
            activeStatus === 6
              ? 'py-2 px-2 cursor-pointer  border-b-2 border-main text-darkerblue font-semibold   ml-12  text-base   leading-none text-center'
              : 'py-2 px-2 cursor-pointer ml-10 bg-transparent hover:bg-lightGrayBg font-semibold   text-base   leading-none text-gray5'
          }
        >
          Events
        </li>
      </ul>
      <div className='md:hidden relative w-11/12 mx-auto bg-white rounded'>
        <div className='lg:hidden w-full flex justify-center'>
          <div className='flex flex-row lg:hidden py-1 w-full'>
            <button
              aria-label='Open Menu'
              title='Open Menu'
              className='px-2 py-2 cursor-pointer  border-b-2 border-main text-darkerblue font-semiboldtext-base   leading-none text-center  flex flex-row w-full justify-between  transition duration-200 rounded focus:outline-none focus:shadow-outline hover:bg-deep-purple-50 focus:bg-deep-purple-50'
              onClick={() => setIsMenuOpen(true)}
            >
              <span className=''>{selectedTab}</span>
              <div>
                <ExpandMore fontSize='inherit' />
              </div>
            </button>
          </div>

          {isMenuOpen && (
            <div className='absolute top-0 left-0 w-full z-50'>
              <div className='p-5 bg-white border rounded shadow-sm'>
                <nav>
                  <ul className='space-y-4'>
                    <li
                      onClick={() => {
                        setActiveStatus(1);
                        setIsMenuOpen(false);
                        setSelectedTab('Home');
                      }}
                      className={
                        activeStatus === 1
                          ? 'py-2 px-2 cursor-pointer  border-b-2 border-main text-darkerblue font-semibold  text-base   leading-none text-center '
                          : 'py-2 px-2 cursor-pointer  bg-transparent hover:bg-lightGrayBg font-semibold   text-base   leading-none text-gray5'
                      }
                    >
                      Home
                    </li>
                    {/*<li
                      onClick={() => {
                        setActiveStatus(2);
                        setIsMenuOpen(false);
                        setSelectedTab('About');
                      }}
                      className={
                        activeStatus === 2
                          ? 'py-2 px-2 cursor-pointer  border-b-2 border-main text-darkerblue font-semibold    text-base   leading-none text-center'
                          : 'py-2 px-2 cursor-pointer bg-transparent hover:bg-lightGrayBg font-semibold   text-base   leading-none text-gray5'
                      }
                    >
                      About
                    </li>*/}
                    <li
                      onClick={() => {
                        setActiveStatus(3);
                        setIsMenuOpen(false);
                        setSelectedTab('Feed');
                      }}
                      className={
                        activeStatus === 3
                          ? 'py-2 px-2 cursor-pointer  border-b-2 border-main text-darkerblue font-semibold    text-base   leading-none text-center '
                          : 'py-2 px-2 cursor-pointer bg-transparent hover:bg-lightGrayBg font-semibold   text-base   leading-none text-gray5'
                      }
                    >
                      Feed
                    </li>
                    <li
                      onClick={() => {
                        setActiveStatus(4);
                        setIsMenuOpen(false);
                        setSelectedTab('Mentors');
                      }}
                      className={
                        activeStatus === 4
                          ? 'py-2 px-2 cursor-pointer  border-b-2 border-main text-darkerblue font-semibold    text-base   leading-none text-center'
                          : 'py-2 px-2 cursor-pointer bg-transparent hover:bg-lightGrayBg font-semibold   text-base   leading-none text-gray5'
                      }
                    >
                      Mentors
                    </li>
                    <li
                      onClick={() => {
                        setActiveStatus(7);
                        setIsMenuOpen(false);
                        setSelectedTab('Members');
                      }}
                      className={
                        activeStatus === 7
                          ? 'py-2 px-2 cursor-pointer  border-b-2 border-main text-darkerblue font-semibold    text-base   leading-none text-center'
                          : 'py-2 px-2 cursor-pointer bg-transparent hover:bg-lightGrayBg font-semibold   text-base   leading-none text-gray5'
                      }
                    >
                      Members
                    </li>
                    <li
                      onClick={() => {
                        setActiveStatus(8);
                        setIsMenuOpen(false);
                        setSelectedTab('Courses');
                      }}
                      className={
                        activeStatus === 8
                          ? 'py-2 px-2 cursor-pointer  border-b-2 border-main text-darkerblue font-semibold    text-base   leading-none text-center'
                          : 'py-2 px-2 cursor-pointer bg-transparent hover:bg-lightGrayBg font-semibold   text-base   leading-none text-gray5'
                      }
                    >
                      Courses
                    </li>
                    <li
                      onClick={() => {
                        setActiveStatus(5);
                        setIsMenuOpen(false);
                        setSelectedTab('Library');
                      }}
                      className={
                        activeStatus === 5
                          ? 'py-2 px-2 cursor-pointer  border-b-2 border-main text-darkerblue font-semibold    text-base   leading-none text-center'
                          : 'py-2 px-2 cursor-pointer bg-transparent hover:bg-lightGrayBg font-semibold   text-base   leading-none text-gray5'
                      }
                    >
                      Library
                    </li>
                    <li
                      onClick={() => {
                        setActiveStatus(6);
                        setIsMenuOpen(false);
                        setSelectedTab('Events');
                      }}
                      className={
                        activeStatus === 6
                          ? 'py-2 px-2 cursor-pointer  border-b-2 border-main text-darkerblue font-semibold    text-base   leading-none text-center'
                          : 'py-2 px-2 cursor-pointer bg-transparent hover:bg-lightGrayBg font-semibold   text-base   leading-none text-gray5'
                      }
                    >
                      Events
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const TabLayout = ({
  institution,
  comments,
  myMentors,
  institutionLearners,
}) => {
  const [activeStatus, setActiveStatus] = useState(1);
  const { institutionID } = useParams();
  const dispatch = useDispatch();
  const content = useSelector((state) => state.content.data);
  const institutions = useSelector((state) => state?.institution?.data);

  const selectedInstitution = institutions.filter(
    (institute) => institute._id === institutionID,
  );

  const adminId = selectedInstitution[0]?.admins[0];

  // const mentor = useSelector((state) => state.mentors.mentor);
  const mentors = useSelector((state) => state?.user?.data);

  useEffect(() => {
    dispatch(userActions.getMentors({}));
  }, []);
  const mentorss = [];
  mentors?.map((value) => mentorss.push(value));
  const mentor = mentorss.find((ment) => ment._id === `${institutionID}`);
  const users = useSelector((state) => state?.user?.data);
  const [showCommentPost, setShowCommentPost] = useState(false);
  const [showSendPost, setShowSendPost] = useState(false);
  const [showSharePost, setShowSharePost] = useState(false);
  const [selectedPost, setSelectedPost] = useState({});

  let myContent = [];
  if (Array.isArray(content)) {
    myContent = content?.filter((item) => item.userID === adminId);
  } else {
    myContent = content;
  }

  return (
    <>
      <div className='text-center lg:text-left'>
        <NavigationHeader
          activeStatus={activeStatus}
          setActiveStatus={setActiveStatus}
        />
        {activeStatus === 1 && (
          <Home
            mentor={mentor}
            myContent={myContent}
            users={users}
            setActiveStatus={setActiveStatus}
            institution={institution}
            setSelectedPost={setSelectedPost}
            setShowSendPost={setShowSendPost}
            setShowCommentPost={setShowCommentPost}
            setShowSharePost={setShowSharePost}
          />
        )}
        {/*activeStatus === 2 && (
          <About
            institution={institution}
            comments={comments}
            userData={users}
          />
        )*/}
        {activeStatus === 3 && (
          <Feeds
            institution={institution}
            adminId={adminId}
            myContent={myContent}
            users={users}
            setSelectedPost={setSelectedPost}
            setShowSendPost={setShowSendPost}
            setShowCommentPost={setShowCommentPost}
            setShowSharePost={setShowSharePost}
          />
        )}
        {activeStatus === 4 && (
          <Mentors
            institution={institution}
            mentors={myMentors}
            users={users}
          />
        )}
        {activeStatus === 7 && (
          <Members
            institution={institution}
            members={institutionLearners}
            users={users}
          />
        )}
        {activeStatus === 8 && (
          <Courses institution={institution} courses={[]} users={users} />
        )}
        {activeStatus === 5 && (
          <Library
            institution={institution}
            adminId={adminId}
            content={content}
          />
        )}
        {activeStatus === 6 && <Events adminId={adminId} />}
      </div>
      <SendPostModal
        setShowSendPost={setShowSendPost}
        showSendPost={showSendPost}
        postSnippet={selectedPost}
        users={users}
      />
      <CommentPostModal
        setShowCommentPost={setShowCommentPost}
        showCommentPost={showCommentPost}
        postSnippet={selectedPost}
        users={users}
      />
      <SharePostModal
        setShowSharePost={setShowSharePost}
        showSharePost={showSharePost}
        postSnippet={selectedPost}
        users={users}
      />
    </>
  );
};

/*const About = ({ institution, comments }) => {
  const institutions = useSelector((state) => state?.institution.data);
  const [slice, setSlice] = useState(5);
  const handleMoreReview = () => {
    setSlice(slice + 4);
  };
  return (
    <div className='px-4 lg:px-0 max-w-full  lg:max-w-full md:mb-12 pt-4'>
      <div className='border-b border-linecolor pb-10 mt-6'>
        <h1 className='font-bold text-charcoal mb-3'>Overview</h1>
        <span className='font-base text-charcoal'>{institution?.bio}</span>
      </div>
      <div className=' flex flex-col justify-around py-2 lg:p-8 rounded-lg mb-9'>
        <div className='flex flex-row justify-between mb-9 text-left text-sm  lg:text-base'>
          <span className='font-base text-gray5 lg:w-3/12'>Type</span>
          <div className=' lg:w-9/12'>
            <span className='font-base text-charcoal  lg:w-9/12'>
              {`Education`}
            </span>
          </div>
        </div>
        <div className='flex flex-row justify-between mb-9 text-left text-sm  lg:text-base'>
          <span className='font-base text-gray5 lg:w-3/12'>Specialities</span>
          <div className=' lg:w-9/12'>
            <span className='font-base text-charcoal  lg:w-9/12'>
              {institution?.specialities?.map((value) => (
                <span className='lg:mr-1' key={value}>
                  {value}
                  {', '}
                </span>
              ))}
            </span>
          </div>
        </div>
        <div className='flex flex-row justify-between mb-9 text-left text-sm  lg:text-base'>
          <span className='font-base text-gray5 lg:w-3/12'>Location</span>
          <div className=' lg:w-9/12'>
            <div className='py-1 px-3 lg:py-2 lg:px-6 font-base text-lightBlue bg-linecolor rounded-lg w-max'>
              <Room fontSize='small' />{' '}
              <span className='ml-2'>
                {' '}
                {institution?.location || `Kampala, Uganda`}
              </span>
            </div>
          </div>
        </div>
        <div className='flex flex-row justify-between mb-9 text-left text-sm  lg:text-base'>
          <span className='font-base text-gray5 lg:w-3/12'>Website</span>
          <div className='ml-4 lg:ml-0 lg:w-9/12'>
            <a
              href='/'
              className='font-base text-darkerblue lg:w-9/12 text-right'
            >
              {institution?.website || 'Not Avaliable'}
            </a>
          </div>
        </div>

        <div className='flex flex-row justify-between mb-9 text-left text-sm  lg:text-base'>
          <span className='font-base text-gray5 lg:w-3/12'>Social Media</span>
          <div className=' lg:w-9/12'>
            <div className='flex justify-start'>
              <a href='/'>
                <img src={Facebook} alt='facebook-link' className='pr-6' />
              </a>
              <a href='/'>
                <img src={Twitter} alt='twitter-link' className='pr-6' />
              </a>
              <a href='/'>
                <img src={LinkedIn} alt='linkedin-link' className='pr-6' />
              </a>
            </div>
          </div>
        </div>
        <div className='flex flex-col mt-4 px-2'>
          <div className='w-full flex lg:flex-row flex-col justify-between pt-6 pb-2'>
            <span className='text-lg text-gray font-semibold px-2  mb-4 '>
              {' '}
              Recent reviews from users on Delv.
            </span>
          </div>
          <div className='flex lg:justify-start pb-4'>
            <select className='appearance-none ainline-flex justify-center w-max  px-2 lg:px-6 py-3 font-normal text-headingtext text-sm focus:outline-noone border border-lightGrayBg2  focus:border-linecolor lg:text-base transition duration-200 rounded-lg bg-lightGrayBg2 hover:bg-white hover:text-graybg2 focus:shadow-outline focus:outline-none'>
              <option>Sort By: Time</option>
              <option>Rating Ascending</option>
              <option>Rating Descending</option>
            </select>
          </div>
          {comments.length > 0 ? (
            <div>
              {comments?.slice(0, slice)?.map((user, key) => (
                <ReviewItem
                  key={user._id}
                  data={user}
                  institutions={institutions}
                />
              ))}
            </div>
          ) : (
            <NoContentPlaceholder content='Reviews' generic />
          )}

          <div className='flex justify-center py-3'>
            <span
              className='font-semibold text-headingtext hover:text-navyblue cursor-pointer text-sm'
              onClick={() => handleMoreReview()}
              disabled={slice >= comments?.length}
            >
              {slice >= comments?.length
                ? comments?.length === 0
                  ? ''
                  : 'No more reviews'
                : 'Show more reviews'}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};*/
const Home = ({
  setActiveStatus,
  institution,
  myContent,
  users,
  setSelectedPost,
  setShowSendPost,
  setShowCommentPost,
  setShowSharePost,
}) => {
  const history = useHistory();
  const [hideDescription, toggleHideDescription] = useToggle(true);
  const latestVideo = myContent.filter((item) => item?.category === 'Video');
  let length = latestVideo.length > 0 ? latestVideo.length - 1 : 0;
  const video = latestVideo[length];
  return (
    <div className='max-w-full  lg:max-w-full md:mb-12 pt-4'>
      <div className='border-b border-linecolor pb-10 mt-6'>
        <h1 className='font-bold text-charcoal mb-3'>About</h1>
        <span className='font-base text-charcoal'>
          {hideDescription && (
            <Truncate lines={3} ellipsis={<span>...</span>}>
              {institution?.bio}
            </Truncate>
          )}
        </span>
        <span className='font-base text-charcoal ' hidden={hideDescription}>
          {institution?.bio}
        </span>
        <span
          className='cursor-pointer text-main font-semibold hover:text-navyblue px-4'
          aria-label='see more'
          aria-controls='see more'
          aria-haspopup='true'
          onClick={() => toggleHideDescription()}
        >
          see {hideDescription ? 'more' : 'less'}
        </span>
        <p
          className='text-center font-semibold text-darkerblue hover:text-navyblue cursor-pointer pt-8 hover:underline'
          onClick={() => {
            setActiveStatus(2);
          }}
        >
          Show more details
        </p>
      </div>

      <div className='border-b border-linecolor pb-10 mt-6'>
        <h1 className='font-bold text-charcoal mb-3'>Recently Posted Videos</h1>
        <div className='flex flex-col lg:flex-row justify-between'>
          <div className='relative w-full lg:w-3/6 transition-shadow duration-300 hover:shadow-xl'>
            <img
              className='object-cover w-full h-48 rounded shadow-lg'
              src={video?.thumbnailFile?.publicUrl}
              alt='Video Thumb'
            />
            <span
              onClick={() => history.push(`/video/watch/${video?._id}`)}
              aria-label='Play Video'
              className='cursor-pointer absolute inset-0 flex items-center justify-center w-full h-full transition-colors duration-300 bg-charcoal bg-opacity-50 group hover:bg-opacity-25'
            >
              <div className='flex items-center justify-center text-white text-5xl w-16 h-16 transition duration-300 transform rounded-full shadow-2xl group-hover:scale-110'>
                <PlayCircleFilledWhite fontSize='inherit' />
              </div>
            </span>
          </div>

          <div className='w-full lg:w-3/6 mx-auto px-4'>
            <span className='font-base text-charcoal '>
              {video?.description}
            </span>
          </div>
        </div>
        <p
          onClick={() => {
            setActiveStatus(5);
          }}
          className='text-center font-semibold text-darkerblue hover:text-navyblue cursor-pointer pt-8 hover:underline'
        >
          Show more videos
        </p>
      </div>
      <div className='border-b border-linecolor pb-10 mt-6 h-full'>
        <h1 className='font-bold text-charcoal mb-3'>Page Posts</h1>
        <div className=''>
          {myContent.length > 0 ? (
            <PostCarousel
              type={'default'}
              content={myContent?.map((value, key) => (
                <PostCard
                  carousel
                  users={users}
                  key={value._id}
                  content={value}
                  sendPostClick={() => {
                    setSelectedPost(value);
                    setShowSendPost(true);
                  }}
                  commentPostClick={() => {
                    setSelectedPost(value);
                    setShowCommentPost(true);
                  }}
                  sharePostClick={() => {
                    setSelectedPost(value);
                    setShowSharePost(true);
                  }}
                />
              ))}
            />
          ) : (
            <NoContentPlaceholder content='Posts' generic />
          )}
        </div>
        <p
          className='text-center font-semibold text-darkerblue hover:text-navyblue cursor-pointer pt-8 hover:underline'
          onClick={() => {
            setActiveStatus(3);
          }}
        >
          Show more posts
        </p>
      </div>
    </div>
  );
};

const Feeds = ({
  users,
  myContent,
  setSelectedPost,
  setShowSendPost,
  setShowCommentPost,
  setShowSharePost,
}) => {
  const [slice, setSlice] = useState(3);
  const handleMoreContent = () => {
    setSlice(slice + 2);
  };
  return (
    <div className='py-4'>
      {myContent.length > 0 ? (
        <div className='grid grid-cols-1 gap-8 mt-4 lg:px-12 h-full overflow-y-auto'>
          {myContent?.slice(0, slice)?.map((value) => (
            <PostCard
              users={users}
              key={value._id}
              content={value}
              sendPostClick={() => {
                setSelectedPost(value);
                setShowSendPost(true);
              }}
              commentPostClick={() => {
                setSelectedPost(value);
                setShowCommentPost(true);
              }}
              sharePostClick={() => {
                setSelectedPost(value);
                setShowSharePost(true);
              }}
            />
          ))}
        </div>
      ) : (
        <NoContentPlaceholder content='Feeds' generic />
      )}

      <div className='flex justify-center py-3'>
        <span
          className='font-semibold text-headingtext hover:text-navyblue cursor-pointer text-sm'
          onClick={() => handleMoreContent()}
          disabled={slice >= myContent?.length}
        >
          {slice >= myContent?.length
            ? myContent?.length === 0
              ? ''
              : 'No more feeds to show'
            : 'Show more Feeds'}
        </span>
      </div>
    </div>
  );
};

const Members = ({ members, users }) => {
  const [slice, setSlice] = useState(9);
  const handleMoreContent = () => {
    setSlice(slice + 3);
  };
  return (
    <div className='max-w-full  lg:max-w-full md:mb-12 pt-4'>
      <div className=' mt-6'>
        <h1 className='font-bold text-charcoal mb-6'>Institution Members</h1>
        {members.length > 0 ? (
          <div className='grid gap-5 sm:row-gap-8 mb-8 lg:grid-cols-3 sm:grid-cols-2'>
            {members?.slice(0, slice)?.map((value) => (
              <MemberCard
                key={value?._id}
                member={value}
                to={`/users/profile/${value?.learner?._id}`}
              />
            ))}
          </div>
        ) : (
          <NoContentPlaceholder content='users' generic />
        )}
      </div>
      <div className='flex justify-center py-3'>
        <span
          className='font-semibold text-headingtext hover:text-navyblue cursor-pointer text-sm'
          onClick={() => handleMoreContent()}
          disabled={slice >= members?.length}
        >
          {slice >= members?.length
            ? members?.length === 0
              ? ''
              : 'No more Members to show'
            : 'Show more Members'}
        </span>
      </div>
    </div>
  );
};

const Courses = ({ courses, users }) => {
  const [slice, setSlice] = useState(9);
  const handleMoreContent = () => {
    setSlice(slice + 3);
  };
  return (
    <div className='max-w-full  lg:max-w-full md:mb-12 pt-4'>
      <div className=' mt-6'>
        <h1 className='font-bold text-charcoal mb-6'>Institution Courses</h1>
        {courses.length > 0 ? (
          <div className='grid gap-5 sm:row-gap-8 mb-8 lg:grid-cols-3 sm:grid-cols-2'>
            {courses?.slice(0, slice)?.map((value) => (
              <MemberCard
                key={value?._id}
                member={value}
                to={`/users/profile/${value?.learner?._id}`}
              />
            ))}
          </div>
        ) : (
          <NoContentPlaceholder content='Course' generic />
        )}
      </div>
      <div className='flex justify-center py-3'>
        <span
          className='font-semibold text-headingtext hover:text-navyblue cursor-pointer text-sm'
          onClick={() => handleMoreContent()}
          disabled={slice >= courses?.length}
        >
          {slice >= courses?.length
            ? courses?.length === 0
              ? ''
              : 'No more Courses to show'
            : 'Show more Courses'}
        </span>
      </div>
    </div>
  );
};

const Mentors = ({ mentors, users }) => {
  const [slice, setSlice] = useState(9);
  const handleMoreContent = () => {
    setSlice(slice + 3);
  };
  return (
    <div className='max-w-full  lg:max-w-full md:mb-12 pt-4'>
      <div className=' mt-6'>
        <h1 className='font-bold text-charcoal mb-6'>Affiliated mentors</h1>
        {mentors.length > 0 ? (
          <div className='grid gap-5 sm:row-gap-8 mb-8 lg:grid-cols-3 sm:grid-cols-2'>
            {mentors?.slice(0, slice)?.map((value) => (
              <MentorCard
                users={users}
                key={value?._id}
                mentor={value}
                to={`/institution/mentor/${value?._id}`}
              />
            ))}
          </div>
        ) : (
          <NoContentPlaceholder content='users' generic />
        )}
      </div>
      <div className='flex justify-center py-3'>
        <span
          className='font-semibold text-headingtext hover:text-navyblue cursor-pointer text-sm'
          onClick={() => handleMoreContent()}
          disabled={slice >= mentors?.length}
        >
          {slice >= mentors?.length
            ? mentors?.length === 0
              ? ''
              : 'No more Mentors to show'
            : 'Show more Mentors'}
        </span>
      </div>
    </div>
  );
};

const Library = ({ adminId, content }) => {
  const myContent = content.filter((item) => item.userID === adminId);

  const myVideos = [];
  myContent?.map((value) =>
    value?.category === 'Video' ? myVideos.push(value) : null,
  );

  const myArticles = [];
  myContent?.map((value) =>
    value?.category === 'Article' ? myArticles.push(value) : null,
  );

  return (
    <div className='w-full sm:text-center  pt-4'>
      <div className=''>
        <div className='px-2  container mx-auto sm:max-w-full md:max-w-full lg:max-w-full md:px-24 lg:px-8 pt-6'>
          <div className='flex flex-col  lg:justify-between '>
            <div className='relative grid gap-5 grid-cols-4 mb-6 lg:mb-2'>
              <OverviewCard
                itemName='Articles'
                icon={<LibraryBooks fontSize={'inherit'} />}
                itemSize={myArticles?.length || 0}
              />

              <OverviewCard
                itemName='Videos'
                icon={<VideoLibrary fontSize={'inherit'} />}
                itemSize={myVideos?.length || 0}
              />
            </div>
          </div>
        </div>
        <div className='px-2  container mx-auto sm:max-w-full md:max-w-full lg:max-w-full md:px-24 lg:px-8 lg:pt-14'>
          <div className='flex flex-col  md:justify-between md:flex-row '>
            <h2 className='max-w-lg mb-5 font-sans text-2xl font-bold tracking-tight text-gray  sm:leading-none group'>
              <span className='inline-block mb-1 sm:mb-4'>
                Videos ({myVideos?.length || 0})
              </span>
            </h2>
          </div>
          {myVideos.length > 0 ? (
            <CarouselController
              type='post'
              content={myVideos.map((value, key) => (
                <LibraryContentCard content={value} type='Video' key={key} />
              ))}
            />
          ) : (
            <NoContentPlaceholder content='Videos' generic />
          )}
        </div>
        <div className='px-2  container mx-auto sm:max-w-full md:max-w-full lg:max-w-full md:px-24 lg:px-8 lg:pt-14'>
          <div className='flex flex-col  md:justify-between md:flex-row '>
            <h2 className='max-w-lg mb-5 font-sans text-2xl font-bold tracking-tight text-gray  sm:leading-none group'>
              <span className='inline-block mb-1 sm:mb-4'>
                Articles ({myArticles?.length || 0})
              </span>
            </h2>
          </div>
          {myArticles.length > 0 ? (
            <CarouselController
              type='post'
              content={myArticles.map((value, key) => (
                <LibraryContentCard content={value} type='Article' key={key} />
              ))}
            />
          ) : (
            <NoContentPlaceholder content='Articles' generic />
          )}
        </div>
      </div>
    </div>
  );
};

const OverviewCard = ({ icon, itemName, itemSize }) => {
  return (
    <div className='flex justify-start overflow-hidden text-left transition-shadow duration-200 bg-none rounded-xl shadow-md3 group p-2'>
      <div className='p-px lg:py-2 lg:px-2'>
        <div className='flex flex-row justify-start '>
          <div className='flex flex-col items-center justify-between w-10 text-textparagraph text-2xl mr-2'>
            {icon}
            <p className='leading-5 text-main text-lg font-semibold pt-2'>
              {itemSize}
            </p>
          </div>
          <div className='flex '>
            <p className='mb-2 font-semibold text-headingtext text-md capitalize'>
              {itemName}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const Events = ({ adminId }) => {
  const dispatch = useDispatch();
  const sessions = useSelector((state) => state?.sessions);

  useEffect(() => {
    dispatch(sessionsActions.getAllSessions());
  }, []);

  const allSessions = sessions?.data;

  const mySession = allSessions.filter((value) => value?.userID === adminId);

  return (
    <div className='max-w-full  lg:max-w-full md:mb-12 pt-4'>
      <div className='pb-10 mt-6'>
        <div>
          <EventSection eventList={mySession} />
        </div>
      </div>
    </div>
  );
};

const EventSection = ({ eventList }) => {
  const today = moment();

  const upcomingSessions = [];
  const pastSessions = [];

  eventList.forEach((value) => {
    if (moment(value?.sessionDate) < today) {
      pastSessions.push(value);
    } else {
      upcomingSessions.push(value);
    }
  });

  const [slice, setSlice] = useState(3);
  const handleMoreContent = () => {
    setSlice(slice + 2);
  };
  const [slice2, setSlice2] = useState(3);
  const handleMoreContent2 = () => {
    setSlice2(slice2 + 2);
  };

  return (
    <>
      <div className='flex flex-col mb-6 lg:justify-between md:mb- shadow '>
        <div className='grid grid-cols-1 gap-6 row-gap-4 mb-8'>
          <div className='border border-linecolor bg-white pb-10  rounded-lg p-4'>
            <h1 className='font-bold text-charcoal mb-6 mt-2  font-sans text-xl  tracking-tight sm:text-2xl sm:leading-none'>
              Upcoming sessions
            </h1>
            <div className='grid gap-8 row-gap-5 md:grid-cols-1'>
              {upcomingSessions.length > 0 ? (
                <div>
                  {upcomingSessions
                    ?.reverse()
                    ?.slice(0, slice)
                    ?.map((value, key) => (
                      <EventCard
                        event={value}
                        key={value._id}
                        past={false}
                        to={`/joinsession/${value._id}`}
                      />
                    ))}
                </div>
              ) : (
                <NoContentPlaceholder type='Upcoming' content='sessions' />
              )}
              <div className='flex justify-center pt-1'>
                <span
                  className='font-semibold text-headingtext hover:text-navyblue cursor-pointer text-sm'
                  onClick={() => handleMoreContent()}
                  disabled={slice >= upcomingSessions?.length}
                >
                  {slice >= upcomingSessions?.length
                    ? upcomingSessions?.length === 0
                      ? ''
                      : 'No more sessions to show'
                    : 'Show more Sessions'}
                </span>
              </div>
            </div>
          </div>
          <div className='border border-linecolor bg-white pb-10 mt-6 rounded-lg p-4'>
            <h1 className='font-bold text-charcoal mb-6 mt-2  font-sans text-xl  tracking-tight sm:text-2xl sm:leading-none'>
              Past sessions
            </h1>
            <div className='grid gap-8 row-gap-5 md:grid-cols-1'>
              {pastSessions.length > 0 ? (
                <div>
                  {pastSessions
                    ?.reverse()
                    ?.slice(0, slice2)
                    ?.map((value, key) => (
                      <EventCard event={value} key={key} past={true} />
                    ))}
                </div>
              ) : (
                <NoContentPlaceholder type='Past' content='sessions' />
              )}
              <div className='flex justify-center pt-1'>
                <span
                  className='font-semibold text-headingtext hover:text-navyblue cursor-pointer text-sm'
                  onClick={() => handleMoreContent2()}
                  disabled={slice2 >= pastSessions?.length}
                >
                  {slice2 >= pastSessions?.length
                    ? pastSessions?.length === 0
                      ? ''
                      : 'No more sessions to show'
                    : 'Show more Sessions'}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const MentorCard = ({ mentor, to, users }) => {
  const mentorName = `${mentor.firstname} ${mentor.lastname}`;
  const mentorSpecialization = mentor?.interests?.join(', ');
  return (
    <Link to={to} aria-label='Mentor More info'>
      <div className='bg-white rounded-md shadow-md text-center border-b-4 border-white w-full h-52 pb-4 px-2 inline-block overflow-hidden duration-300 transform hover:-translate-y-2 hover:border-darkerblue mb-3  mx-4'>
        <div className='w-20 h-20 m-auto mt-6 mb-3.5 '>
          <img
            src={
              mentor?.profilePicture?.publicUrl ||
              `https://ui-avatars.com/api/?name=${mentorName}&bold=true`
            }
            className='object-cover w-full h-full rounded-full'
            alt={mentor?.sessionTitle}
          />
        </div>
        <div className='bg-gray '></div>
        <h2 className='text-xl font-bold m-auto mb-2  capitalize text-charcoal'>
          {/* {name} */}
          {mentorName}
        </h2>
        <p className=' block mb-3 font-base capitalize text-charcoal text-opacity-70'>
          {mentorSpecialization}
        </p>
      </div>
    </Link>
  );
};

const MemberCard = ({ member, to }) => {
  const memberName = `${member?.learner?.firstName} ${member?.learner?.lastName}`;
  return (
    <Link to={to} aria-label='Member More info'>
      <div className='bg-white rounded-md shadow-md text-center border-b-4 border-white w-full h-52 pb-4 px-2 inline-block overflow-hidden duration-300 transform hover:-translate-y-2 hover:border-darkerblue mb-3  mx-4'>
        <div className='w-20 h-20 m-auto mt-6 mb-3.5 '>
          <img
            src={
              member?.profilePicture?.publicUrl ||
              `https://ui-avatars.com/api/?name=${memberName}&bold=true`
            }
            className='object-cover w-full h-full rounded-full'
            alt={member?.sessionTitle}
          />
        </div>
        <div className='bg-gray '></div>
        <h2 className='text-xl font-bold m-auto mb-2  capitalize text-charcoal'>
          {/* {name} */}
          {memberName}
        </h2>
        <p className=' block mb-3 font-base capitalize text-charcoal text-opacity-70'>
          Member
        </p>
      </div>
    </Link>
  );
};

export default InstitutionFindoutMore;
