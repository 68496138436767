import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

export const CarouselController = ({ content, type }) => {
  let responsive;
  if (type === 'default') {
    responsive = {
      superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 5,
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 4,
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 3,
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
      },
    };
  } else if (type === 'post') {
    responsive = {
      desktop: {
        breakpoint: { max: 6000, min: 1024 },
        items: 2,
        slidesToSlide: 2, // optional, default to 1.
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
        slidesToSlide: 2, // optional, default to 1.
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1, // optional, default to 1.
      },
    };
  } else if (type === 'custom') {
    responsive = {
      desktop: {
        breakpoint: { max: 6000, min: 1024 },
        items: 4,
        slidesToSlide: 2, // optional, default to 1.
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 3,
        slidesToSlide: 2, // optional, default to 1.
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1, // optional, default to 1.
      },
    };
  }

  return (
    <Carousel
      responsive={responsive}
      infinite={false}
      autoPlay={false}
      autoPlaySpeed={5000}
      transitionDuration={500}
      removeArrowOnDeviceType={[]}
    >
      {content}
    </Carousel>
  );
};

export const PostCarousel = ({ content }) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 6000, min: 1024 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  return (
    <Carousel
      // /////////////////////////
      additionalTransfrom={0}
      arrows
      autoPlaySpeed={3000}
      centerMode={false}
      className=''
      draggable
      focusOnSelect={false}
      infinite
      keyBoardControl
      minimumTouchDrag={80}
      partialVisible
      renderDotsOutside={false}
      /////////////////////////////////
      swipeable={true}
      responsive={responsive}
      // ssr={true} // means to render carousel on server-side.
      autoPlay={false}
      customTransition='all .5'
      transitionDuration={500}
      containerClass='carousel-container'
      removeArrowOnDeviceType={['tablet', 'mobile']}
      dotListClass='custom-dot-list-style'
      itemClass='carousel-item-padding-40-px'
    >
      {content}
    </Carousel>
  );
};
